import React from 'react';
import './SocialMediaFooter.scss';
import { useLayoutContext } from '../../../providers/LayoutProvider';
import { AnalyticsController } from '../../Analytics';

export const SocialMediaFooter = () => {
    const iconSize = `3rem`;
    const { isPolicy } = useLayoutContext();
    const socialIcons = {
        twitter: isPolicy
            ? `https://twitter.com/CharterGov`
            : `https://twitter.com/charternewsroom`,
        facebook: isPolicy ? `http://www.facebook.com/CharterGov` : ``,
        youtube: isPolicy
            ? ``
            : `https://www.youtube.com/chartercommunications`,
    };
    const name = isPolicy ? `Charter Policy` : `Charter Communications`;

    return (
        <section className="footer__social-media">
            {isPolicy && <h4 className="typestack-title-5 ">Follow {name}</h4>}
            <ul>
                {socialIcons.twitter && (
                    <li>
                        <a
                            href={socialIcons.twitter}
                            data-fsm-share-type="twitter"
                            rel="noopener"
                            target="_blank"
                            aria-label={`${name} Twitter feed`}
                            onClick={() => {
                                AnalyticsController.track({
                                    event: `global footer click`,
                                    globalFooterLink: `twitter`,
                                });
                            }}
                        >
                            <span
                                className="kite-icon"
                                style={{
                                    height: iconSize,
                                    width: iconSize,
                                }}
                            >
                                <span className="kite-icon__inner">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="23"
                                        viewBox="0 0 28 21"
                                    >
                                        <g fill="none" fillRule="evenodd">
                                            <path
                                                fill="#FFF"
                                                d="M28 2.729a12.002 12.002 0 0 1-3.295.92A5.79 5.79 0 0 0 27.241.42a11.512 11.512 0 0 1-3.66 1.419A5.657 5.657 0 0 0 19.377 0c-3.159 0-5.74 2.604-5.74 5.8 0 .468.076.905.167 1.326C9.02 6.892 4.783 4.584 1.958 1.06c-.485.874-.789 1.856-.789 2.916A5.843 5.843 0 0 0 3.72 8.81a5.447 5.447 0 0 1-2.595-.717v.078c0 .748.136 1.434.394 2.089a5.768 5.768 0 0 0 4.222 3.587c-.502.14-.988.218-1.519.218-.364 0-.729-.047-1.079-.125.745 2.308 2.856 3.992 5.362 4.054a11.423 11.423 0 0 1-7.122 2.48c-.472 0-.911-.032-1.382-.079A16.261 16.261 0 0 0 8.807 23c8.974 0 14.517-6.378 15.958-13.005.26-1.185.38-2.354.38-3.508v-.749A12.055 12.055 0 0 0 28 2.728"
                                            />
                                        </g>
                                    </svg>
                                </span>
                            </span>
                        </a>
                    </li>
                )}
                {socialIcons.facebook && (
                    <li>
                        <a
                            href={socialIcons.facebook}
                            className="FollowOnSocialMedia_Link external-processed"
                            data-fsm-share-type="facebook"
                            rel="noopener"
                            target="_blank"
                            aria-label="Spectrum Facebook channel"
                            onClick={() => {
                                AnalyticsController.track({
                                    event: `global footer click`,
                                    globalFooterLink: `facebook`,
                                });
                            }}
                        >
                            <span
                                className="kite-icon"
                                style={{
                                    height: iconSize,
                                    width: iconSize,
                                }}
                            >
                                <span className="kite-icon__inner">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="13"
                                        height="32"
                                        viewBox="0 0 13 32"
                                    >
                                        <g fill="none" fillRule="evenodd">
                                            <path
                                                fill="#FFF"
                                                d="M8.45 8.31V6.08c0-1.068.699-1.324 1.204-1.324h3.074V0H8.496C3.787 0 2.733 3.522 2.733 5.81v2.5H0v5.554h2.763v13.88h5.48v-13.88h4.055l.178-2.183.326-3.372H8.451z"
                                            ></path>
                                        </g>
                                    </svg>
                                </span>
                            </span>
                        </a>
                    </li>
                )}
                {socialIcons.youtube && (
                    <li>
                        <a
                            href={socialIcons.youtube}
                            className=""
                            data-fsm-share-type="youtube"
                            rel="noopener"
                            target="_blank"
                            aria-label={`${name} YouTube channel`}
                            onClick={() => {
                                AnalyticsController.track({
                                    event: `global footer click`,
                                    globalFooterLink: `youtube`,
                                });
                            }}
                        >
                            <span
                                className="kite-icon"
                                style={{
                                    height: iconSize,
                                    width: iconSize,
                                }}
                            >
                                <span className="kite-icon__inner">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="21"
                                        viewBox="0 0 28 21"
                                    >
                                        <g fill="none" fillRule="evenodd">
                                            <path
                                                fill="#FFF"
                                                d="M18.531 9.94l-7.512 4.076V5.833l3.403 1.865L18.53 9.94zm8.972-5.515s-.286-2.015-1.114-2.895C25.336.38 24.146.364 23.619.288 19.735 0 13.91 0 13.91 0h-.03s-5.81 0-9.695.288C3.628.364 2.454.379 1.4 1.53.572 2.41.287 4.425.287 4.425S0 6.788 0 9.122v2.227c0 2.364.287 4.697.287 4.697s.285 2.016 1.113 2.91c1.054 1.166 2.44 1.121 3.072 1.242 2.212.227 9.438.303 9.438.303s5.825-.03 9.71-.303c.526-.076 1.716-.076 2.769-1.243.828-.893 1.114-2.909 1.114-2.909s.27-2.333.27-4.697V9.122c0-2.334-.27-4.697-.27-4.697z"
                                            ></path>
                                        </g>
                                    </svg>
                                </span>
                            </span>
                        </a>
                    </li>
                )}
            </ul>
        </section>
    );
};
