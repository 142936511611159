import axios from 'axios';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { useFlexSearch } from '../shared/useFlexSearch';

export const SearchContext = React.createContext({
    query: ``,
    searchResults: [] as SearchResult[],
    loading: true,
    error: false,
    searchValid: false,
    searchFocused: false,
    searchIndex: undefined,
    searchStore: undefined,
    loadSearch: () => {
        //
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    searchHandler: (query: string) => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    searchFocusedHandler: () => {},
    popularSearchTerms: [] as string[],
});

export const SearchContextProvider = ({ children }) => {
    const MIN_SEARCH_LENGTH = 3;

    const data = useStaticQuery(graphql`
        query remoteSearchPages {
            localSearchPages {
                publicIndexURL
                publicStoreURL
            }
            allTaxonomyTermPopularSearchTerms {
                nodes {
                    name
                }
            }
        }
    `);

    const popularSearchTerms =
        data.allTaxonomyTermPopularSearchTerms?.nodes?.map((n) => n.name);

    const remoteSearchDataUrls = data.localSearchPages;

    const [query, setQuery] = useState(``);
    const [searchFocused, setSearchFocused] = useState(false);
    const [searchError, setSearchError] = useState(false);
    const [searchDataLoading, setSearchDataLoading] = useState(true);
    const [searchValid, setSearchValid] = useState(false);
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);

    const [searchIndex, setSearchIndex] = useState(null);
    const [searchStore, setSearchStore] = useState(null);

    const flexsearch = useFlexSearch(
        //query.replace(/[-]+/g, ``),
        query,
        searchIndex,
        searchStore
    );

    const searchHandler = (text: string) => {
        setQuery(text);
    };

    useEffect(() => {
        if (
            !query ||
            query.trim().length < MIN_SEARCH_LENGTH ||
            searchDataLoading ||
            searchError
        ) {
            setSearchValid(false);
            setSearchResults([]);
            return;
        }
        setSearchValid(true);
        setSearchResults(flexsearch);
    }, [query, searchIndex, searchStore, searchError]);

    const searchFocusedHandler = async () => {
        if (!searchFocused) {
            setSearchFocused(true);
            setSearchDataLoading(true);

            try {
                const indexUrl = data.localSearchPages.publicIndexURL;
                const publicStoreURL = data.localSearchPages.publicStoreURL;
                const result = await Promise.all([
                    axios.get(indexUrl),
                    axios.get(publicStoreURL),
                ]);
                setSearchDataLoading(false);
                setSearchIndex(result[0].data);
                setSearchStore(result[1].data);
                searchHandler(query);
            } catch (e) {
                // eslint-disable-next-line
                console.error(e);
                setSearchError(true);
                setSearchDataLoading(false);
            }
        }
    };

    const loadSearch = async () => {
        if (searchIndex && searchStore) return;
        try {
            const indexUrl = data.localSearchPages.publicIndexURL;
            const publicStoreURL = data.localSearchPages.publicStoreURL;
            const result = await Promise.all([
                axios.get(indexUrl),
                axios.get(publicStoreURL),
            ]);
            setSearchDataLoading(false);
            setSearchIndex(result[0].data);
            setSearchStore(result[1].data);
            searchHandler(query);
        } catch (e) {
            // eslint-disable-next-line
            console.error(e);
            setSearchError(true);
            setSearchDataLoading(false);
        }
    };

    return (
        <SearchContext.Provider
            value={{
                query,
                searchHandler,
                searchFocusedHandler,
                loadSearch,
                loading: searchDataLoading,
                error: searchError,
                searchIndex,
                searchStore,
                searchResults,
                searchValid,
                searchFocused,
                popularSearchTerms,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export const useSearchContext = () => useContext(SearchContext);

export interface SearchResult {
    title: string;
    url: string;
    category: string;
    body: string;
    date?: string;
}
