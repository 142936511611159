import React from 'react';
import { useLayoutContext } from '../../providers/LayoutProvider';
import {
    breakpointDown,
    SIDEBAR_BREAKPOINT_DOWN,
} from '../../shared/breakpoints';

import { BottomLinksFooter } from './BottomLinksFooter/BottomLinksFooter';
import './Footer.scss';
import { MainMenu } from '../Header/MainMenu/MainMenu';

import { TopActionFooter } from './TopActionFooter/TopActionFooter';

interface Props {
    dimensions: {
        width: number;
        height: number;
    };
}

export const Footer = ({ dimensions }: Props) => {
    const { heroCarouselRef } = useLayoutContext();
    const carouselMargin = heroCarouselRef.current !== undefined ? true : false;

    return (
        <footer className={`footer__main ${carouselMargin ? `carousel` : ``}`}>
            <TopActionFooter />
            <MainMenu
                setSidebarOpen={breakpointDown(
                    SIDEBAR_BREAKPOINT_DOWN,
                    dimensions.width
                )}
                footer={true}
            />
            <BottomLinksFooter />
        </footer>
    );
};
