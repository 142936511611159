import { Link } from 'gatsby';
import React from 'react';
import './MainMenuSubMenu.scss';
import { MenuItem } from '../../../models/MenuItem';
import { useLayoutContext } from '../../../providers/LayoutProvider';
import { processLink } from '../../../shared/processLink';
import { AnalyticsController } from '../../Analytics';

interface Props {
    ariaHidden?: boolean;
    items: MenuItem[];
    footer?: boolean;
    title: string;
    titleId: string;
    setSidebarOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    handleKeyDown?: (e: React.KeyboardEvent) => void;
    handleClose?: () => void;
}
export const MainMenuSubMenu = ({
    ariaHidden,
    items,
    setSidebarOpen,
    footer,
    title,
    titleId,
    handleClose,
    handleKeyDown,
}: Props) => {
    const { isPolicy } = useLayoutContext();

    const handleBlur = (idx: number) => {
        if (idx === items.length - 1) {
            handleClose();
        }
    };

    const onClick = (title: string) => {
        if (typeof window !== `undefined`) {
            (window.document.activeElement as HTMLElement).blur();
        }
        if (setSidebarOpen) {
            setSidebarOpen(false);
        }
        if (!footer) {
            AnalyticsController.track({
                event: `global header click`,
                globalHeaderLink: title,
            });
        } else {
            AnalyticsController.track({
                event: `global footer click`,
                globalFooterLink: title,
            });
        }
    };

    return (
        <ul id={titleId} aria-label={title} aria-hidden={ariaHidden}>
            {items
                .sort((a, b) => parseFloat(a.weight) - parseFloat(b.weight))
                .filter((item) => item.url)
                .map((item, idx) => {
                    const processedUrl = processLink(
                        item.url,
                        isPolicy,
                        undefined,
                        item.menu_name.includes(`policy`) ? `ga` : `cew`
                    );
                    if (processedUrl.startsWith(`/`)) {
                        return (
                            <li
                                key={item.id}
                                onKeyDown={handleKeyDown}
                                onBlur={() => handleBlur(idx)}
                            >
                                <Link
                                    to={processedUrl}
                                    onClick={() => {
                                        onClick(item.title);
                                    }}
                                >
                                    {item.title}
                                </Link>
                            </li>
                        );
                    }

                    return (
                        <li
                            key={item.id}
                            onKeyDown={handleKeyDown}
                            onBlur={() => handleBlur(idx)}
                        >
                            <a
                                href={processedUrl}
                                onClick={() => {
                                    onClick(item.title);
                                }}
                            >
                                {item.title}
                            </a>
                        </li>
                    );
                })}
        </ul>
    );
};
