import React from 'react';
import './BottomLinksFooter.scss';
import logo from '../../../assets/img/charter-logo-white.svg';
import { sortMenuItems } from '../../../shared/sortMenuItems';
import { graphql, useStaticQuery } from 'gatsby';
import { AnalyticsController } from '../../Analytics';

export const BottomLinksFooter = () => {
    const sitesMenuData = useStaticQuery(graphql`
        query {
            allMenuItems(filter: { menu_name: { eq: "footer" } }) {
                nodes {
                    title
                    menu_name
                    id
                    url
                    weight
                    childrenMenuItems {
                        id
                        title
                        weight
                        url
                    }
                }
            }
        }
    `);
    const items = sitesMenuData.allMenuItems.nodes.sort(sortMenuItems);

    return (
        <article className="footer__bottom">
            <section className="kite-container with-logo">
                <a
                    href="https://corporate.charter.com"
                    aria-label="Go back to home page"
                    className="img"
                    onClick={() => {
                        AnalyticsController.track({
                            event: `global footer click`,
                            globalFooterLink: `charter logo`,
                        });
                    }}
                >
                    <img src={logo} alt="Charter Communications logo" />
                </a>
                <span>
                    © {new Date().getFullYear()} Charter Communications, all
                    rights reserved.
                </span>
                <div className="links">
                    {items.map((link) => (
                        <a
                            key={link.url}
                            href={link.url}
                            onClick={() => {
                                AnalyticsController.track({
                                    event: `global footer click`,
                                    globalFooterLink: link.title,
                                });
                            }}
                        >
                            {link.title}
                        </a>
                    ))}
                </div>
            </section>
            <div className="dis">
                If you are a customer with disability, please{` `}
                <a href="https://www.spectrum.net/support/accessibility/talking-guide-information-and-support/">
                    contact us
                </a>
                {` `}
                if you need assistance.
            </div>
        </article>
    );
};
