export const sortMenuItems = (a, b) => {
    try {
        const aWeight = parseInt(a.weight);
        const bWeight = parseInt(b.weight);
        if (aWeight === bWeight) {
            return a.title - b.title;
        }
        return aWeight - bWeight;
    } catch {
        return a.title - b.title;
    }
};
