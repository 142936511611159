import React from 'react';
import { KiteIcon } from '@kite/react-kite';

export const SidebarTrigger = ({ sidebarOpen, setSidebarOpen, searchOpen }) => {
    return (
        <button
            className="sidebar-trigger"
            aria-label="Open sidebar menu, which contains search and navigation"
            disabled={sidebarOpen || searchOpen}
            onClick={() => {
                setSidebarOpen(true);
            }}
        >
            <span
                className="kite-icon"
                style={{ height: `1.5rem`, width: `1.5rem` }}
            >
                <span className="kite-icon__inner">
                    <svg
                        role=""
                        focusable="false"
                        fillRule="evenodd"
                        viewBox="0 0 32 32"
                        data-icon="ki-menu"
                        aria-hidden="true"
                        aria-describedby="icon-title-m"
                    >
                        <title id="icon-title-m"></title>
                        <path d="M28.00045,7.99953h-25v-2h25a1,1,0,0,1,1,1h0A1,1,0,0,1,28.00045,7.99953Z"></path>
                        <path d="M28.00045,16.99953h-25v-2h25a1,1,0,0,1,1,1h0A1,1,0,0,1,28.00045,16.99953Z"></path>
                        <path d="M28.00045,25.99953h-25v-2h25a1,1,0,0,1,1,1h0A1,1,0,0,1,28.00045,25.99953Z"></path>
                    </svg>
                </span>
            </span>
        </button>
    );
};
