import { KiteGridCell, KiteIcon } from '@kite/react-kite';
import React from 'react';
import { MenuItem } from '../../../models/MenuItem';
import { cleanLink } from '../../../shared/cleanLink';
import { sortMenuItems } from '../../../shared/sortMenuItems';
import { AnalyticsController } from '../../Analytics';
import './SitesMenuSubMenu.scss';

interface Props {
    items: MenuItem[];
    title: string;
}
export const SitesMenuSubMenu = ({ items, title }: Props) => {
    const menuId = cleanLink(title);
    return (
        <KiteGridCell col={3} className="sites-nav-submenu">
            <span id={`menu-h-${menuId}`} className="typestack-title-4 heading">
                {title}
            </span>
            <ul role="menu" aria-labelledby={`menu-h-${menuId}`}>
                {items.sort(sortMenuItems).map((item, idx) => (
                    <li key={idx}>
                        <a
                            href={item.url}
                            onClick={() => {
                                AnalyticsController.track({
                                    event: `global header click`,
                                    globalHeaderLink: `spectrum sites: ${item.title}`,
                                });
                            }}
                        >
                            {item.title.toLowerCase() === `instagram` && (
                                <span
                                    className="kite-icon"
                                    style={{ height: `1rem`, width: `1rem` }}
                                >
                                    <span className="kite-icon__inner">
                                        <svg
                                            role=""
                                            focusable="false"
                                            fillRule="evenodd"
                                            viewBox="0 0 32 32"
                                            data-icon="ki-instagram"
                                            aria-hidden="true"
                                            aria-describedby="icon-title-ig"
                                        >
                                            <title id="icon-title-ig"></title>
                                            <path d="M16,5.34234c3.47116,0,3.8823.01324,5.25311.07578a7.19337,7.19337,0,0,1,2.41392.44763A4.30517,4.30517,0,0,1,26.13425,8.333a7.19362,7.19362,0,0,1,.44763,2.41391c.06254,1.37082.07578,1.782.07578,5.25312s-.01324,3.8823-.07578,5.25311a7.19363,7.19363,0,0,1-.44763,2.41392A4.30517,4.30517,0,0,1,23.667,26.13425a7.19363,7.19363,0,0,1-2.41392.44763c-1.37061.06254-1.78172.07578-5.25311.07578s-3.8825-.01324-5.25311-.07578A7.19363,7.19363,0,0,1,8.333,26.13425,4.30517,4.30517,0,0,1,5.86575,23.667a7.19336,7.19336,0,0,1-.44763-2.41391C5.35558,19.8823,5.34234,19.47116,5.34234,16s.01324-3.8823.07578-5.25311A7.19337,7.19337,0,0,1,5.86575,8.333,4.30517,4.30517,0,0,1,8.333,5.86575a7.19336,7.19336,0,0,1,2.41391-.44763c1.37082-.06254,1.782-.07578,5.25312-.07578M16,3c-3.53059,0-3.9733.01495-5.35988.07822a9.54191,9.54191,0,0,0-3.15563.60426,6.64744,6.64744,0,0,0-3.802,3.802,9.54191,9.54191,0,0,0-.60426,3.15563C3.01495,12.0267,3,12.4694,3,16s.01495,3.9733.07822,5.35988a9.54191,9.54191,0,0,0,.60426,3.15563,6.64744,6.64744,0,0,0,3.802,3.802,9.54191,9.54191,0,0,0,3.15563.60426C12.0267,28.98506,12.46941,29,16,29s3.9733-.01494,5.35988-.07822a9.54191,9.54191,0,0,0,3.15563-.60426,6.64744,6.64744,0,0,0,3.802-3.802,9.54191,9.54191,0,0,0,.60426-3.15563C28.98505,19.9733,29,19.53059,29,16s-.01495-3.9733-.07822-5.35988a9.54191,9.54191,0,0,0-.60426-3.15563,6.64744,6.64744,0,0,0-3.802-3.802,9.54191,9.54191,0,0,0-3.15563-.60426C19.9733,3.01495,19.53059,3,16,3Z"></path>
                                            <path d="M16,9.32432A6.67568,6.67568,0,1,0,22.67568,16,6.67568,6.67568,0,0,0,16,9.32432Zm0,11.009A4.33333,4.33333,0,1,1,20.33333,16,4.33333,4.33333,0,0,1,16,20.33333Z"></path>
                                            <circle
                                                cx="22.93943"
                                                cy="9.06057"
                                                r="1.56"
                                            ></circle>
                                        </svg>
                                    </span>
                                </span>
                            )}
                            {item.title.toLowerCase() === `facebook` && (
                                <span
                                    className="kite-icon"
                                    style={{ height: `1rem`, width: `1rem` }}
                                >
                                    <span className="kite-icon__inner">
                                        <svg
                                            role=""
                                            focusable="false"
                                            fillRule="evenodd"
                                            viewBox="0 0 32 32"
                                            data-icon="ki-facebook"
                                            aria-hidden="true"
                                            aria-describedby="icon-title-fb"
                                        >
                                            <title id="icon-title-fb"></title>
                                            <path d="M30,16A14.002,14.002,0,0,1,18.19,29.83V20.05h3.26L22.07,16H18.19V13.37a2.02042,2.02042,0,0,1,2.28-2.18h1.76V7.74a21.73685,21.73685,0,0,0-3.13-.27c-3.2,0-5.29,1.94-5.29,5.45V16H10.26v4.05h3.55v9.78A14.00106,14.00106,0,1,1,30,16Z"></path>
                                        </svg>
                                    </span>
                                </span>
                            )}
                            {item.title.toLowerCase() === `twitter` && (
                                <span
                                    className="kite-icon"
                                    style={{ height: `1rem`, width: `1rem` }}
                                >
                                    <span className="kite-icon__inner">
                                        <svg
                                            role=""
                                            focusable="false"
                                            fillRule="evenodd"
                                            viewBox="0 0 32 32"
                                            data-icon="ki-twitter"
                                            aria-hidden="true"
                                            aria-describedby="icon-title-tw"
                                        >
                                            <title id="icon-title-tw"></title>
                                            <path d="M3,5V27a2,2,0,0,0,2,2H27a2,2,0,0,0,2-2V5a2,2,0,0,0-2-2H5A2,2,0,0,0,3,5Zm19.46288,7.685c.00924.14855.00924.28786.00924.4364A9.48567,9.48567,0,0,1,7.875,21.10714a7.506,7.506,0,0,0,.78926.04642,6.68873,6.68873,0,0,0,4.14145-1.43A3.33951,3.33951,0,0,1,9.695,17.41139a3.42756,3.42756,0,0,0,1.50428-.05571A3.34761,3.34761,0,0,1,8.525,14.08715v-.04642a3.33375,3.33375,0,0,0,1.51358.41781A3.33279,3.33279,0,0,1,9.00783,10.0107a9.48162,9.48162,0,0,0,6.87145,3.48215,3.34029,3.34029,0,0,1,5.68285-3.04575,6.74932,6.74932,0,0,0,2.11717-.80785,3.37113,3.37113,0,0,1-1.46715,1.84791,6.8983,6.8983,0,0,0,1.91285-.5293A6.83568,6.83568,0,0,1,22.46288,12.685Z"></path>
                                        </svg>
                                    </span>
                                </span>
                            )}
                            {item.title.toLowerCase() === `youtube` && (
                                <span
                                    className="kite-icon"
                                    style={{ height: `1rem`, width: `1rem` }}
                                >
                                    <span className="kite-icon__inner">
                                        <svg
                                            role=""
                                            focusable="false"
                                            fillRule="evenodd"
                                            viewBox="0 0 32 32"
                                            data-icon="ki-youtube"
                                            aria-hidden="true"
                                            aria-describedby="icon-title-yt"
                                        >
                                            <title id="icon-title-yt"></title>
                                            <path d="M27,3H5A2.00587,2.00587,0,0,0,3,5V27a2.00591,2.00591,0,0,0,2,2H27a2.00591,2.00591,0,0,0,2-2V5A2.00587,2.00587,0,0,0,27,3ZM24.62,20.36a2.238,2.238,0,0,1-1.59,1.6,53.18666,53.18666,0,0,1-7.03.38,53.18666,53.18666,0,0,1-7.03-.38,2.238,2.238,0,0,1-1.59-1.6A23.46756,23.46756,0,0,1,7,16a23.46756,23.46756,0,0,1,.38-4.36,2.23821,2.23821,0,0,1,1.59-1.6A53.18666,53.18666,0,0,1,16,9.66a53.18666,53.18666,0,0,1,7.03.38,2.23821,2.23821,0,0,1,1.59,1.6A23.46756,23.46756,0,0,1,25,16,23.46756,23.46756,0,0,1,24.62,20.36Z"></path>
                                            <path d="M18.86 16L14.16 18.68 14.16 13.32 18.86 16z"></path>
                                        </svg>
                                    </span>
                                </span>
                            )}
                            {item.title}
                        </a>
                    </li>
                ))}
            </ul>
        </KiteGridCell>
    );
};
