import React from 'react';
import { useLayoutContext } from '../../../providers/LayoutProvider';
import { CTASubscribeFooter } from '../CTASubscribeFooter/CTASubscribeFooter';
import { SocialMediaFooter } from '../SocialMediaFooter/SocialMediaFooter';
import './TopActionFooter.scss';

export const TopActionFooter = () => {
    const { isPolicy } = useLayoutContext();
    return (
        <div className="footer__ctas">
            <div className="kite-container">
                {isPolicy && <CTASubscribeFooter />}
                <SocialMediaFooter />
            </div>
        </div>
    );
};
