import React from 'react';
import './SitesMenu.scss';
import { SitesMenuSubMenu } from './SitesMenuSubMenu';
import { KiteGrid } from '@kite/react-kite';
import { graphql, useStaticQuery } from 'gatsby';
import { sortMenuItems } from '../../../shared/sortMenuItems';

export const SitesMenu = () => {
    const sitesMenuData = useStaticQuery(graphql`
        query {
            allMenuItems(filter: { menu_name: { eq: "sites" } }) {
                nodes {
                    title
                    menu_name
                    id
                    url
                    weight
                    childrenMenuItems {
                        id
                        title
                        weight
                        url
                    }
                }
            }
        }
    `);

    const parentItems = sitesMenuData.allMenuItems.nodes
        .filter((item) => item.childrenMenuItems.length > 0)
        .sort(sortMenuItems);

    return (
        <KiteGrid className="kite-container sites__menu">
            {!!parentItems &&
                parentItems.map((item) => {
                    return (
                        <SitesMenuSubMenu
                            key={item.id}
                            items={item.childrenMenuItems}
                            title={item.title}
                        />
                    );
                })}
        </KiteGrid>
    );
};
