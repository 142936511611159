import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { CURRENT_SITE } from '../../../../config-sites';
import { AnalyticsController } from '../../Analytics';
import { load } from 'recaptcha-v3';
import './CTASubscribeFooter.scss';

export const CTASubscribeFooter = () => {
    const [formBuildId, setFormBuildId] = useState(`form-`);
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);

    useEffect(() => {
        setFormBuildId(`form-${Date.now()}`);
        load('6LeWVBAkAAAAAFfVGqUIYK5_JUC5PcBf8yJpiGHt', {
            explicitRenderParameters: {
                container: 'recaptcha',
                badge: `bottomleft`,
            }
        });
    }, []);
 
    const onSubmit = async (evt) => {
        setSubmitting(true);
        setFail(false);
        evt.preventDefault();
        AnalyticsController.track({
            event: `form submit`,
            formName: `alerts sign-up`,
        });
        const formData = new FormData(evt.target);
        
        if (typeof window !== `undefined`) {
            const captchaResponse = window.grecaptcha?.getResponse();
            try {
                if (!captchaResponse) {
                    setFail(true);
                    setSuccess(false)
                } else {
                    await axios.postForm(CURRENT_SITE.endpoint, formData);
                    setSuccess(true)
                }
            } catch {
                setFail(true);
            }
        }
        setSubmitting(false);
    };

    return (
        <section className="footer__cta-subscribe left">
            <h4 className="typestack-title-5">Subscribe to Policy Updates</h4>
            {!success && (
                <>
                    <form
                        method="post"
                        action="/"
                        onSubmit={onSubmit}                   
                    >
                        <input
                            hidden
                            autoComplete="off"
                            type="hidden"
                            name="form_build_id"
                            value={formBuildId}
                        />
                        <input
                            hidden
                            autoComplete="off"
                            type="hidden"
                            name="form_id"
                            value="mailchimp_signup_subscribe_block_footer_newsletter_form"
                        />
                        <div className="input-group">
                            <label htmlFor="ctaSubscribe">Email Address</label>
                            <input
                                id="ctaSubscribe"
                                name="mergevars[EMAIL]"
                                type="email"
                                maxLength={254}
                                aria-required="true"
                                required
                                disabled={submitting}
                            />
                        </div>
                        <input
                            className="kite-button kite-button--shrinkwrap kite-button--primary kite-button--sm"
                            type="submit"
                            value="Sign Up Now"
                            disabled={submitting}
                        />
                    </form>
                    <div className="recaptcha-container" >
                        <div id="recaptcha" ></div>
                    </div>
                </>
            )}
            {fail && (
                <p className="error">
                    An error occurred. Please try again later.
                </p>
            )}
            {success && (
                <p>Please check your email to confirm your subscription.</p>
            )}
        </section>
    );
};
