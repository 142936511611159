import { CURRENT_SITE } from '../../config-sites';
import { URLS } from '../../config-urls';

/**
 * will transform links to hardcoded hosts (e.g. corporate.charter.com)
 * into relative links in the same environment.
 *
 * Allows the site to be swapped, too
 */
export const processLink = (
    link: string,
    isPolicy: boolean,
    nodeLookup?: any,
    targetSite?: `cew` | `ga`
) => {
    const listToUse = isPolicy ? URLS.ga : URLS.cew;
    if (!link) return `#`;
    if (link.includes(`internal:`)) {
        link = link.replace(`internal:`, ``);
    }
    if (link === `/homepage` || link === `/home`) {
        link = `/`;
    }
    if (link.includes(`entity:node/`)) {
        const nodeId = link.split(`/`)[1];
        if (nodeLookup) {
            const matchingNode = nodeLookup.find(
                (node) =>
                    node.pageContext.drupal_internal__nid?.toString() === nodeId
            );
            if (matchingNode) {
                return matchingNode.pageContext.fields.url;
            }
        }
    }
    if (link.startsWith(`/`)) {
        if (targetSite) {
            for (const envName in listToUse) {
                if (typeof window !== `undefined`) {
                    if (listToUse[envName].includes(window.location.host)) {
                        return `${window.location.protocol}//${
                            URLS[targetSite][envName][
                                listToUse[envName].indexOf(window.location.host)
                            ]
                        }${link}`;
                    }
                }
            }
        } else {
            return link;
        }
    }
    try {
        const url = new URL(link);
        for (const envName in listToUse) {
            if (listToUse[envName].includes(url.host)) {
                if (!targetSite) {
                    return url.pathname;
                } else {
                    return URLS[targetSite][envName][
                        listToUse[envName].indexOf(url.host)
                    ];
                }
            }
        }

        return link;
    } catch {
        return link;
    }
};
