import React, { useCallback, useState } from 'react';
import './Header.scss';
import logo from '../../assets/img/charter-logo-v2.svg';
import { MainMenu } from './MainMenu/MainMenu';
import { PolicyHeader } from './PolicyHeader/PolicyHeader';
import { SearchInput } from './SearchInput/SearchInput';
import { SearchResults } from '../SearchResults/SearchResults';
import { SearchTrigger } from './SearchTrigger/SearchTrigger';
import { SidebarTrigger } from './SidebarTrigger/SidebarTrigger';
import { SitesMenu } from './SitesMenu/SitesMenu';
import { useLayoutContext } from '../../providers/LayoutProvider';
import { AnalyticsController } from '../Analytics';

interface Props {
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    searchOpen: boolean;
    setSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onBlur?: () => void;
    searchInline?: boolean;
}

export const Header = ({
    sidebarOpen,
    setSidebarOpen,
    searchOpen,
    setSearchOpen,
    onBlur,
    searchInline,
}: Props) => {
    const [sitesNavExpanded, setSitesNavExpanded] = useState(false);
    const { headerRef, isPolicy, sitesNavRef } = useLayoutContext();
    const [headerHeight, setHeaderHeight] = useState(0);

    const handleTabOnBlur = useCallback(
        (event) => {
            const currentTarget = event.currentTarget;
            requestAnimationFrame(() => {
                if (!currentTarget.contains(document.activeElement)) {
                    setSitesNavExpanded(false);
                }
            });
        },
        [onBlur]
    );

    return (
        <>
            <header
                className={`site-header ${sitesNavExpanded ? `expanded` : ``}`}
                ref={sitesNavRef}
                onBlur={handleTabOnBlur}
            >
                <div className="kite-container">
                    <button
                        onClick={() => {
                            setSitesNavExpanded(!sitesNavExpanded);
                        }}
                        aria-label={`${
                            sitesNavExpanded ? `Collapse` : `Expand`
                        } Spectrum Sites navigation section`}
                    >
                        Shop Spectrum Services
                    </button>
                </div>
                <nav
                    className={`sites-nav ${
                        sitesNavExpanded ? `expanded` : ``
                    }`}
                    aria-hidden={!sitesNavExpanded}
                >
                    <SitesMenu />
                </nav>
            </header>
            <div
                className={`prevent-reflow ${
                    sitesNavExpanded ? `expanded` : ``
                }`}
            ></div>
            <header
                ref={headerRef}
                className={`nav-header ${isPolicy ? `policy ` : ``}${
                    searchInline ? `search-inline` : ``
                }`}
            >
                <div className="kite-container">
                    {!isPolicy && (
                        <SidebarTrigger
                            sidebarOpen={sidebarOpen}
                            setSidebarOpen={setSidebarOpen}
                            searchOpen={searchOpen}
                        />
                    )}
                    <a
                        href="https://corporate.charter.com"
                        rel="home"
                        aria-hidden={!isPolicy && searchOpen}
                        className={!isPolicy && searchOpen ? `hidden` : ``}
                        onClick={() => {
                            AnalyticsController.track({
                                event: `global header click`,
                                globalHeaderLink: `charter logo`,
                            });
                        }}
                    >
                        <img src={logo} alt="Charter Communications logo" />
                    </a>
                    {!(!isPolicy && searchOpen) && <MainMenu />}
                    {!isPolicy && !searchOpen && (
                        <SearchTrigger setSearchOpen={setSearchOpen} />
                    )}
                    {!isPolicy && searchOpen && (
                        <SearchInput
                            searchOpen={searchOpen}
                            setSearchOpen={setSearchOpen}
                        />
                    )}
                </div>
                {!isPolicy && searchOpen && (
                    <SearchResults setSearchOpen={setSearchOpen} />
                )}
            </header>
            {isPolicy && (
                <PolicyHeader
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                    searchOpen={searchOpen}
                    setSearchOpen={setSearchOpen}
                    searchInline={searchInline}
                    refHeaderHeight={headerHeight}
                />
            )}
        </>
    );
};
