import { KiteIcon, KiteProgressIndicator } from '@kite/react-kite';
import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { SearchContext, useSearchContext } from '../../../providers/SearchProvider';
import './SidebarSearch.scss';
interface Props {
    searchFocused: boolean;
    setSearchFocused: React.Dispatch<React.SetStateAction<boolean>>;
    hasSearchResults: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SidebarSearch = ({
    searchFocused,
    setSearchFocused,
    hasSearchResults,
    setSidebarOpen,
}: Props) => {
    const {
        query,
        searchValid,
        searchResults,
        loadSearch,
        loading,
        searchHandler,
        searchFocusedHandler,
    } = useSearchContext();
    const searchContext = useContext(SearchContext);
    const handleChange = (event) => {
        searchContext.searchHandler(event.target.value);
    };
    const handleSubmit = (evt) => {
        evt.preventDefault();
        setSearchFocused(false);
        setSidebarOpen(false);
        navigate(`/search?q=${query}`);
    }

    return (
        <form
            className="sidebar__search"
            onSubmit={(event) => {
                handleSubmit(event);
            }}
        >
            <span
                className="kite-icon search-icon"
                style={{ height: `1.5rem`, width: `1.5rem` }}
            >
                <span className="kite-icon__inner">
                    <svg
                        role=""
                        focusable="false"
                        fillRule="evenodd"
                        viewBox="0 0 32 32"
                        data-icon="ki-search"
                        aria-hidden="true"
                        aria-describedby="icon-title-s1"
                    >
                        <title id="icon-title-s1"></title>
                        <path d="M29,27.58594l-6.55212-6.55213a11.01774,11.01774,0,1,0-1.41407,1.41407L27.58594,29ZM5,14a9,9,0,1,1,9,9A9.01047,9.01047,0,0,1,5,14Z"></path>
                    </svg>
                </span>
            </span>
            <input
                onFocus={() => {
                    searchContext.searchFocusedHandler();
                    setSearchFocused(true);
                }}
                aria-label="Search"
                value={searchFocused ? searchContext.query : ``}
                onBlur={(event) => {
                    if (!hasSearchResults || !event.target.value) {
                        //setSearchFocused(false);
                        searchContext.query = ``;
                    }
                }}
                onChange={handleChange}
                placeholder="Search"
            ></input>
            {searchFocused && 
                searchValid && 
                    loading && (
                        <KiteProgressIndicator
                            className="loader"
                            id="searchLoader"
                            size="sm"
                        />
                    )
            }
        </form>
    );
};
