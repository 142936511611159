import React, { useEffect, useState } from 'react';

export const SearchTrigger = ({ setSearchOpen }) => {
    const [bool404Check, setBool404Check] = useState(true);
    const windowUrl = typeof window !== `undefined` ? window.location.href : ``;
    const UrlExists = (windowUrl, cb) => {
        fetch(windowUrl, { method: `head` }).then(function (status) {
            cb(status.ok);
        });
    };

    useEffect(() => {
        setTimeout(() => {
            UrlExists(windowUrl, function (exists) {
                if (!exists) {
                    setBool404Check(false);
                }
            });
        }, 300);
    }, []);

    return (
        <button
            className="search-trigger"
            aria-label="Show search"
            onClick={() => {
                if (bool404Check) {
                    setSearchOpen(true);
                }
            }}
        >
            <span
                className="kite-icon"
                style={{ height: `1.5rem`, width: `1.5rem` }}
            >
                <span className="kite-icon__inner">
                    <svg
                        role=""
                        focusable="false"
                        fillRule="evenodd"
                        viewBox="0 0 32 32"
                        data-icon="ki-search"
                        aria-hidden="true"
                        aria-describedby="icon-title-st"
                    >
                        <title id="icon-title-st"></title>
                        <path d="M29,27.58594l-6.55212-6.55213a11.01774,11.01774,0,1,0-1.41407,1.41407L27.58594,29ZM5,14a9,9,0,1,1,9,9A9.01047,9.01047,0,0,1,5,14Z"></path>
                    </svg>
                </span>
            </span>
        </button>
    );
};
