import React, { useContext, useEffect, useState } from 'react';
import { navigate, Link } from 'gatsby';
import { useLayoutContext } from '../../providers/LayoutProvider';
import { KiteIcon, KiteProgressIndicator } from '@kite/react-kite';
import {
    useSearchContext,
    SearchResult,
    SearchContext,
} from '../../providers/SearchProvider';
import { SearchPagination } from './SearchPagination';
import './SearchResults.scss';
import { getCurrentBreakpoint } from '../../shared/breakpoints';
import { SmartLink } from '../../components/SmartLink/SmartLink';
import { AnalyticsController } from '../Analytics';
import { stripHtml } from '../../shared/stripHTML';
interface Props {
    classNames?: [string];
    searchInline?: boolean;
    sidebar?: boolean;
    setHasSearchResults?: any;
    searchOpen?: boolean;
    setSearchOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    setSidebarOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SearchResults = ({
    searchInline,
    sidebar,
    setHasSearchResults,
    searchOpen,
    setSearchOpen,
    setSidebarOpen,
}: Props) => {
    const { isPolicy } = useLayoutContext();
    const PAGE_SIZE = searchInline ? 10 : 4;
    const searchContext = useContext(SearchContext);
    const {
        searchFocused,
        searchValid,
        query,
        loading,
        error,
        searchResults,
        loadSearch,
        popularSearchTerms,
        searchHandler,
        searchFocusedHandler,
    } = useSearchContext();

    const [numPages, setNumPages] = useState(1);
    const [visibleIndex, setVisibleIndex] = useState(1);
    const [lastIndexForPage, setLastIndexForPage] = useState(0);
    const [visibleResults, setVisibleResults] = useState<SearchResult[]>([]);

    const handlePopTermClick = (term: string) => {
        navigate(`/search?q=${term}`);
        searchHandler(term);
    };

    useEffect(() => {
        const numResults = searchResults.length;
        const newNumPages = Math.max(1, Math.ceil(numResults / PAGE_SIZE));
        setNumPages(newNumPages);
        if (visibleIndex > numResults) {
            setVisibleIndex((newNumPages - 1) * PAGE_SIZE + 1);
        }
        if (setHasSearchResults) {
            setHasSearchResults(numResults > 0);
        }
        if (isPolicy) {
            searchResults
                .sort((c, d) => {
                    // sort by authored date:string converted to Date
                    if (!d.date || !c.date) {
                        return -1;
                    }
                    return (
                        new Date(d.date).valueOf() - new Date(c.date).valueOf() //TSX needs 'valueOf()' - throws error otherwise...
                    );
                })
                //bring type: "page" as first
                .sort((a: any, b: any) => {
                    return b.type.localeCompare(a.type);
                });
        }
    }, [searchResults]);

    useEffect(() => {
        setLastIndexForPage(
            Math.min(visibleIndex + (PAGE_SIZE - 1), searchResults.length)
        );
        setVisibleResults(
            searchResults.filter((res, idx) => {
                return (
                    idx >= visibleIndex - 1 &&
                    idx < visibleIndex - 1 + PAGE_SIZE
                );
            })
        );
    }, [searchResults, visibleIndex]);

    useEffect(() => {
        loadSearch();
    }, []);

    return (
        <>
            {!sidebar && !searchInline && (
                <div className={`header-fill policy-${isPolicy}`}></div>
            )}
            <article
                className={`search__results ${searchInline ? `inline` : ``} ${
                    sidebar ? `in-sidebar` : ``
                }`}
            >
                {!sidebar && (
                    <div className="shade">
                        {searchFocused && searchValid && loading && (
                            <KiteProgressIndicator
                                className="spinner"
                                id="kp"
                            />
                        )}
                    </div>
                )}
                {sidebar && searchFocused && searchValid && loading && (
                    <KiteProgressIndicator className="spinner" id="kp" />
                )}
                <section
                    className={`results kite-container results-${searchValid}`}
                >
                    {error && (
                        <>
                            <h2 className="typestack-title-4">
                                Something went wrong. Please try again later.
                            </h2>
                        </>
                    )}

                    {(!searchResults.length || !searchValid) && (
                        <>
                            {searchValid && !searchResults.length && (
                                <h2 className="typestack-title-3">
                                    No results found for "{searchContext.query}
                                    ".
                                </h2>
                            )}
                            {popularSearchTerms && isPolicy && (
                                <div className="pop-terms">
                                    <h3 className="typestack-eyebrow">
                                        Popular Search Terms
                                    </h3>
                                    <ul className="pop-terms__list">
                                        {popularSearchTerms.map((term) => (
                                            <li key={term}>
                                                <SmartLink
                                                    ariaLabel={`Search for ${term}`}
                                                    classNames={[
                                                        `typestack-title-4`,
                                                    ]}
                                                    url={`/search?q=${term}`}
                                                    onClick={(event) => {
                                                        AnalyticsController.track(
                                                            {
                                                                event: `popular search term click`,
                                                                searchResult:
                                                                    term,
                                                            }
                                                        );
                                                        handlePopTermClick(
                                                            term
                                                        );
                                                        if (setSearchOpen) {
                                                            setSearchOpen(
                                                                false
                                                            );
                                                        }
                                                        if (setSidebarOpen) {
                                                            setSidebarOpen(
                                                                false
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {term}
                                                </SmartLink>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </>
                    )}
                    {searchValid && searchResults.length > 0 && (
                        <>
                            <h2 className="typestack-title-3">
                                {searchInline
                                    ? `${searchResults.length} Results for "${query}"`
                                    : `${
                                          loading ? `Loading ` : ``
                                      }Results for "${query}"`}
                            </h2>
                            <ul>
                                {visibleResults.map((res, index) => (
                                    <li key={visibleResults.indexOf(res)}>
                                        <p className="typestack-eyebrow">
                                            {res.category}
                                        </p>
                                        <SmartLink
                                            classNames={[
                                                `clamped`,
                                                `typestack-title-4`,
                                            ]}
                                            url={res.url}
                                            onClick={() => {
                                                AnalyticsController.track({
                                                    event: `search result click`,
                                                    searchResult: res.title,
                                                });
                                                if (setSearchOpen) {
                                                    setSearchOpen(false);
                                                }
                                                if (setSidebarOpen) {
                                                    setSidebarOpen(false);
                                                }
                                            }}
                                        >
                                            {res.title}
                                        </SmartLink>
                                        {searchInline && res.body ? (
                                            <p className="clamped typestack-body-1">
                                                {stripHtml(
                                                    res.body
                                                        /* this split makes it so that article search results
                                                         only display the subtitle, summary, and body */
                                                        ?.split(`|`)[0]
                                                        .substring(0, 150)
                                                )}
                                            </p>
                                        ) : (
                                            ``
                                        )}
                                        {searchInline ? (
                                            <p className="typestack-eyebrow date">
                                                {res.date}
                                            </p>
                                        ) : (
                                            ``
                                        )}
                                    </li>
                                ))}
                            </ul>
                            {searchInline ? (
                                <SearchPagination
                                    visibleIndex={visibleIndex}
                                    setVisibleIndex={setVisibleIndex}
                                    pageSize={PAGE_SIZE}
                                    numPages={numPages}
                                />
                            ) : (
                                <SmartLink
                                    classNames={[
                                        `link-view-all typestack-clickable`,
                                    ]}
                                    url={`/search?q=${query}`}
                                    onClick={() => {
                                        if (setSearchOpen) {
                                            setSearchOpen(false);
                                        }
                                        if (setSidebarOpen) {
                                            setSidebarOpen(false);
                                        }
                                    }}
                                >
                                    View All Results
                                    <KiteIcon
                                        icon="ki-arrow-right"
                                        size="1.125rem"
                                    ></KiteIcon>
                                </SmartLink>
                            )}
                        </>
                    )}
                </section>
            </article>
        </>
    );
};
