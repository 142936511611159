import { useState, useEffect, useMemo } from 'react';
import FlexSearch from 'flexsearch';
import { useLayoutContext } from '../providers/LayoutProvider';

export const useFlexSearch = (query, providedIndex, store, searchOptions?) => {
    const [index, setIndex] = useState(null);
    const { isPolicy } = useLayoutContext();

    useEffect(() => {
        if (!providedIndex) {
            setIndex(null);
            return;
        }

        if (providedIndex instanceof FlexSearch) {
            setIndex(providedIndex);
            return;
        }

        const importedIndex = isPolicy
            ? FlexSearch.create({
                  encode: `icase`,
                  threshold: 5,
                  depth: 3,
              })
            : FlexSearch.create();

        /* By default, FlexSearch's index import wants a stringified JSON,
        * which it parses, but it will skip this step if we set 'serialize'
         to false in the import function's options argument... which isn't
        * typed and thus throws an error if we provide the perfectly
        * valid config option. So we cast it to any -_-
        */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (importedIndex.import as any)(providedIndex, { serialize: false });

        setIndex(importedIndex);
    }, [providedIndex]);

    return useMemo(() => {
        if (query === undefined || query === null || !index || !store)
            return [];

        const rawResults = index.search(query, searchOptions);
        return rawResults.map((id) => store[id]);
    }, [query, index, store]);
};
