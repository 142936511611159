export type Breakpoint = `xs` | `sm` | `md` | `lg` | `xl` | `xxl`;
export const Breakpoints: { [name in Breakpoint]: number } = {
    xs: 0,
    sm: 480,
    md: 600,
    lg: 840,
    xl: 960,
    xxl: 1280,
};

export const getCurrentBreakpoint = (width: number): Breakpoint => {
    let currentBreakpoint = `xs`;
    for (const breakpoint in Breakpoints) {
        if (Breakpoints[breakpoint] >= width) {
            currentBreakpoint = breakpoint;
        }
    }

    return currentBreakpoint as Breakpoint;
};

export const breakpointDown = (breakpoint: Breakpoint, width: number) => {
    const matchingWidth = Breakpoints[breakpoint];
    return width < matchingWidth;
};

export const breakpointUp = (breakpoint: Breakpoint, width: number) => {
    const matchingWidth = Breakpoints[breakpoint];
    return width >= matchingWidth;
};

export const SIDEBAR_BREAKPOINT_DOWN: Breakpoint = `xl`;
