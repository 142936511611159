import React from 'react';
import { LayoutContextProvider } from '../providers/LayoutProvider';
import { SearchContextProvider } from '../providers/SearchProvider';
import { CURRENT_SITE } from '../../config-sites';
export default ({ element }) => {
    const headerRef = null;
    const sitesNavRef = null;
    const heroCarouselRef = null;

    return (
        <LayoutContextProvider
            value={{
                site: CURRENT_SITE.id,
                isPolicy: CURRENT_SITE.id === `ga`,
                headerRef,
                sitesNavRef,
                heroCarouselRef,
            }}
        >
            <SearchContextProvider>{element}</SearchContextProvider>
        </LayoutContextProvider>
    );
};
