import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { useLayoutContext } from '../../providers/LayoutProvider';
import { processLink } from '../../shared/processLink';

export interface SmartLinkInputs {
    url: string;
    children: any;
    button?: boolean;
    classNames?: string[];
    newWindow?: boolean;
    ariaLabel?: string;
    download?: boolean;
    onClick?: (event?) => void;
    onMouseDown?: (event?) => void;
}

export const SmartLink = (inputs: SmartLinkInputs) => {
    const { isPolicy } = useLayoutContext();

    const target = inputs.newWindow ? `_blank` : undefined;
    const ariaLabel = inputs.ariaLabel ? inputs.ariaLabel : undefined;
    const finalClassNames = inputs.classNames ? [...inputs.classNames] : [];

    if (inputs.button) {
        finalClassNames.push(`kite-button`);
        finalClassNames.push(`kite-button--primary`);
        finalClassNames.push(`kite-button--lg`);
        finalClassNames.push(`kite-button--shrinkwrap`);
    }

    const data = useStaticQuery(graphql`
        query {
            allSitePage {
                nodes {
                    pageContext
                }
            }
        }
    `);

    const classNames =
        finalClassNames.length > 0 ? finalClassNames.join(` `) : undefined;

    /*
        Decide if the link will be an anchor tag or Gatsby <Link>.
        <Link> will only be used for internal links that are not files.
    */
    const fixedUrl = processLink(inputs.url, isPolicy, data.allSitePage.nodes);

    let LinkElement = (
        <a
            className={classNames}
            href={fixedUrl}
            target={target}
            aria-label={ariaLabel}
            onClick={inputs.onClick}
            download={inputs.download}
            onMouseDown={inputs.onMouseDown}
        >
            {inputs.children}
        </a>
    );

    if (fixedUrl.startsWith(`/`) && !fixedUrl.includes(`.`)) {
        LinkElement = (
            <Link
                to={fixedUrl}
                className={classNames}
                title={ariaLabel}
                onClick={inputs.onClick}
                onMouseDown={inputs.onMouseDown}
            >
                {inputs.children}
            </Link>
        );
    }

    return LinkElement;
};
