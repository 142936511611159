export const URL_PREFIXES = [
    {
        name: `Newsroom Articles`,
        nodes: [`node__newsroom_article`, `node__ir_news_article`],
        prefix: () => `newsroom`,
    },
    {
        name: `Leadership`,
        nodes: [`node__leadership`],
        prefix: (node) => (node.field_edic ? `edic` : `leadership`),
    },
];

export const FOLDERS = {
    mediaLibrary: `/public/static/media`,
};

export const URLS = {
    cew: {
        prod: [
            `corporate.charter.com`,
            `corporate.charter.com`,
            `communityimpact.charter.com`,
            `communityimpact.spectrum.com`,
            `corporate.spectrum.com`,
            `newsroom.charter.com`,
            `cew.spectrumtoolbox.com`,
        ],
        stage: [
            `stage-content.cew.ncs.spectrumflow.net`,
            // `stage-content.cew.spectrumtoolbox.com`,
            `stage-preview.cew.ncs.spectrumflow.net`,
            //`stage-preview.cew.spectrumtoolbox.com`,
        ],
        dev: [
            `dev-content.cew.ncs.spectrumflow.net`,
            //`dev-content.cew.spectrumtoolbox.com`,
            `dev-preview.cew.ncs.spectrumflow.net`,
            // `dev-preview.cew.spectrumtoolbox.com`,
            `localhost:8000`,
        ],
    },

    ga: {
        prod: [`policy.charter.com`, `ga.spectrumtoolbox.com`],
        stage: [
            `stage-content.ga.ncs.spectrumflow.net`,
            // `stage-content.ga.spectrumtoolbox.com`,
            `stage-preview.ga.ncs.spectrumflow.net`,
            // `stage-preview.ga.spectrumtoolbox.com`,
        ],
        dev: [
            `dev-content.ga.ncs.spectrumflow.net`,
            //`dev-content.ga.spectrumtoolbox.com`,
            `dev-preview.ga.ncs.spectrumflow.net`,
            // `dev-preview.ga.spectrumtoolbox.com`,
            `localhost:8000`,
        ],
    },
};
