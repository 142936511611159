import { KiteIcon } from '@kite/react-kite';
import React, { useContext } from 'react';
import { useState } from 'react';
import { SearchContext } from '../../providers/SearchProvider';
import { MainMenu } from '../Header/MainMenu/MainMenu';
import { SearchResults } from '../SearchResults/SearchResults';
import './Sidebar.scss';
import { SidebarSearch } from './SidebarSearch/SidebarSearch';

interface Props {
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Sidebar = ({ sidebarOpen, setSidebarOpen }: Props) => {
    const [searchFocused, setSearchFocused] = useState(false);
    const [hasSearchResults, setHasSearchResults] = useState(false);
    const searchContext = useContext(SearchContext);

    const wrapperClasses = [`sidebar`];

    if (sidebarOpen) {
        wrapperClasses.push(`open`);
    }
    if (searchFocused) {
        wrapperClasses.push(`search-focused`);
    }

    return (
        <aside
            className={wrapperClasses.join(` `)}
            aria-hidden={!sidebarOpen}
            aria-expanded={sidebarOpen}
            id="sidebar"
            onClick={(evt) => {
                if ((evt.target as HTMLElement).id === `sidebar`) {
                    setSidebarOpen(false);
                    setHasSearchResults(false);
                    searchContext.query = ``;
                }
            }}
        >
            <article>
                <button
                    className="close"
                    aria-label={
                        searchFocused ? `Cancel search` : `Close the sidebar`
                    }
                    onClick={() => {
                        setHasSearchResults(false);
                        if (searchFocused) {
                            setSearchFocused(false);
                        } else {
                            setSidebarOpen(false);
                            searchContext.query = ``;
                        }
                    }}
                >
                    <span
                        className="kite-icon"
                        style={{ height: `1.5rem`, width: `1.5rem` }}
                    >
                        <span className="kite-icon__inner">
                            <svg
                                role=""
                                focusable="false"
                                fillRule="evenodd"
                                viewBox="0 0 32 32"
                                data-icon="ki-x"
                                aria-hidden="true"
                                aria-describedby="icon-title-xxxx"
                            >
                                <title id="icon-title-xxxx"></title>
                                <path d="M26.707 6.707L25.293 5.293 16 14.586 6.707 5.293 5.293 6.707 14.586 16 5.293 25.293 6.707 26.707 16 17.414 25.293 26.707 26.707 25.293 17.414 16 26.707 6.707z"></path>
                            </svg>
                        </span>
                    </span>
                </button>
                <SidebarSearch
                    searchFocused={searchFocused}
                    setSearchFocused={setSearchFocused}
                    hasSearchResults={hasSearchResults}
                    setSidebarOpen={setSidebarOpen}
                />
                {searchFocused && (
                    <SearchResults
                        sidebar
                        setHasSearchResults={setHasSearchResults}
                        setSidebarOpen={setSidebarOpen}
                    />
                )}
                <MainMenu setSidebarOpen={setSidebarOpen} />
            </article>
        </aside>
    );
};
