import { Script } from 'gatsby';
import React from 'react';
import { useLayoutContext } from '../providers/LayoutProvider';
export const Analytics = () => {
    if (typeof window !== `undefined`) {
        window[`utag_cfg_ovrd`] = {
            noview: true,
        };
    }
    const { isPolicy } = useLayoutContext();
    if (isPolicy) {
        return (
            <Script src="https://tags.tiqcdn.com/utag/charter/govt-affairs/prod/utag.js" />
        );
    }
    return (
        <Script src="https://tags.tiqcdn.com/utag/charter/newsroom/prod/utag.js" />
    );
};

export const AnalyticsController = {
    track: (payload: { [key: string]: string }) => {
        for (const key in payload) {
            if (key !== `event`) {
                payload[key] = payload[key].toLowerCase().trim();
            }
        }
        if (typeof window !== `undefined`) {
            if (!(`utag` in window)) {
                setTimeout(() => AnalyticsController.track(payload), 250);
            } else {
                window[`utag`].link(payload);
            }
        }
    },
    pageView: (payload: { [key: string]: string }) => {
        for (const key in payload) {
            if (key !== `event`) {
                payload[key] = payload[key].toLowerCase().trim();
            }
        }
        if (typeof window !== `undefined`) {
            if (!(`utag` in window)) {
                setTimeout(() => AnalyticsController.pageView(payload), 250);
            } else {
                window[`utag`].view(payload);
            }
        }
    },
};
