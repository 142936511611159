import { KiteIcon } from '@kite/react-kite';
import React, { useEffect, useState } from 'react';
import './SearchPagination.scss';

interface Props {
    visibleIndex: number;
    setVisibleIndex: React.Dispatch<React.SetStateAction<number>>;
    numPages: number;
    pageSize: number;
}

export const SearchPagination = ({
    visibleIndex,
    setVisibleIndex,
    numPages,
    pageSize,
}: Props) => {
    const MAX_PAGE_BUTTONS = 5;

    const [page, setPage] = useState(1);
    const [pages, setPages] = useState<Array<number | string>>([1]);

    useEffect(() => {
        setPage((visibleIndex - 1) / pageSize + 1);
    }, [visibleIndex, pageSize]);

    useEffect(() => {
        let newPages: Array<number | string> = [];
        for (let i = 1; i <= numPages; i++) {
            newPages.push(i);
        }
        /**
         * Don't show too many pages in the paginator.
         * Assuming MAX_PAGE_BUTTONS is 5
         *
         * Example for 9 pages. Three conditions:
         * 1. a page near the middle is selected,
         * in that case, show < 1 ... 4 5 6 ... 9 >
         *
         * 2. a page near the start is selected,
         * in that case, show < 1 2 3 4 ... 9 >
         *
         * 3. a page near the end is selected,
         * in that case, show < 1 ... 6 7 8 9 >
         */
        if (numPages > MAX_PAGE_BUTTONS) {
            if (page < MAX_PAGE_BUTTONS - 2) {
                newPages = newPages.filter(
                    (pItem:number) =>
                        pItem <= MAX_PAGE_BUTTONS - 1 || pItem === numPages
                );
                newPages.splice(MAX_PAGE_BUTTONS - 1, 0, `...`);
            } else if (page > numPages - (MAX_PAGE_BUTTONS - 2)) {
                newPages = newPages.filter(
                    (pItem:number) =>
                        pItem === 1 || pItem > numPages - MAX_PAGE_BUTTONS + 1
                );
                newPages.splice(1, 0, `...`);
            } else {
                newPages = newPages.filter(
                    (pItem:number) =>
                        pItem === 1 ||
                        pItem === numPages ||
                        (pItem >=
                            page - Math.floor((MAX_PAGE_BUTTONS - 2) / 2) &&
                            pItem <=
                                page + Math.floor((MAX_PAGE_BUTTONS - 2) / 2))
                );
                newPages.splice(1, 0, `...`);
                newPages.splice(MAX_PAGE_BUTTONS, 0, `...`);
            }
        }
        setPages(newPages);
    }, [page, numPages]);

    return (
        <section className="search__pagination">
            <h3 className="typestack-body-2">
                Page&nbsp;&nbsp;
                {page}
                &nbsp;&nbsp;of&nbsp;&nbsp;
                {numPages}
            </h3>
            <ul className="controls">
                <li>
                    <button
                        aria-label="Go to previous page of search results"
                        disabled={visibleIndex <= 1}
                        onClick={() => {
                            setVisibleIndex(visibleIndex - pageSize);
                        }}
                    >
                        <KiteIcon icon="ki-chevron-left" size="1rem" />
                        Prev
                    </button>
                </li>
                {pages.map((pageNo, index) => {
                    const isCurrentPage = page === pageNo;

                    return (
                        <li key={`page-${index}`}>
                            {typeof pageNo === `number` && (
                                <button
                                    className="page-no"
                                    disabled={isCurrentPage}
                                    aria-label={`Go to page ${pageNo} of search results`}
                                    onClick={() => {
                                        setVisibleIndex(
                                            (pageNo - 1) * pageSize + 1
                                        );
                                    }}
                                >
                                    {pageNo}
                                </button>
                            )}
                            {typeof pageNo === `string` && <div>...</div>}
                        </li>
                    );
                })}
                <li>
                    <button
                        aria-label="Go to next page of search results"
                        disabled={
                            visibleIndex === pageSize * (numPages - 1) + 1
                        }
                        onClick={() => {
                            setVisibleIndex(visibleIndex + pageSize);
                        }}
                    >
                        Next
                        <KiteIcon icon="ki-chevron-right" size="1rem" />
                    </button>
                </li>
            </ul>
        </section>
    );
};
