import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Layout.scss';
import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';
import { Sidebar } from './Sidebar/Sidebar';
import {
    breakpointDown,
    breakpointUp,
    getCurrentBreakpoint,
    SIDEBAR_BREAKPOINT_DOWN,
} from '../shared/breakpoints';
import { Analytics } from './Analytics';
import {
    MediaLibraryContextProvider,
    MediaLibraryTab,
} from '../providers/MediaLibraryProvider';

export default function Layout({ children }) {
    const [headerHeight, setHeaderHeight] = useState(0);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchInline, setSearchInline] = useState(false);
    const [breakpoint, setBreakpoint] = useState<
        `xs` | `sm` | `md` | `lg` | `xl` | `xxl`
    >(`xl`);
    const [dimensions, setDimensions] = useState({
        height: 0,
        width: 0,
    });
    const href = typeof window !== `undefined` ? window.location.href : ``;
    const [mediaLibraryTabs, setMediaLibraryTabs] = useState<MediaLibraryTab[]>(
        [
            {
                id: `logos`,
                name: `Logos`,
                count: 0,
                cards: [],
                zip: `/static/media/logos.zip`,
                downloadSize: ``,
            },
            {
                id: `images`,
                name: `Images`,
                count: 0,
                cards: [],
                subCategories: [],
                downloadSize: ``,
            },
            {
                id: `videos`,
                name: `Videos`,
                count: 0,
                cards: [],
                zip: `/static/media/videos.zip`,
                downloadSize: ``,
            },
            {
                id: `presskits`,
                name: `Press Kits`,
                count: 0,
                cards: [],
                subCategories: [],
                zip: `/static/media/presskits.zip`,
                downloadSize: ``,
            },
        ]
    );
    useEffect(() => {
        if (href.includes(`search?q`)) {
            setSearchInline(true);
            setSearchOpen(true);
        } else {
            setSearchInline(false);
            setSearchOpen(false);
        }
    }, [href]);

    useEffect(() => {
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth,
        });
        window.addEventListener(`resize`, () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
            if (
                sidebarOpen &&
                breakpointUp(SIDEBAR_BREAKPOINT_DOWN, window.innerWidth)
            ) {
                setSidebarOpen(false);
            }
            setBreakpoint(getCurrentBreakpoint(window.innerWidth));
        });
    }, [setDimensions, sidebarOpen, setBreakpoint, headerHeight]);

    return (
        <MediaLibraryContextProvider
            value={{
                mediaLibraryTabs,
                setMediaLibraryTabs,
            }}
        >
            <div className="skip-to-main">
                <div className="kite-container">
                    <a
                        id="skip-to-main"
                        href="#main-content"
                        className="skip-to-main__link"
                    >
                        Skip to main content
                    </a>
                </div>
            </div>
            <div
                className={`layout ${
                    sidebarOpen || searchOpen ? `static` : ``
                } search-${searchInline}`}
            >
                <Header
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                    searchOpen={searchOpen}
                    setSearchOpen={setSearchOpen}
                    searchInline={searchInline}
                />
                {breakpointDown(SIDEBAR_BREAKPOINT_DOWN, dimensions.width) && (
                    <Sidebar
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />
                )}
                <main id="main-content">{children}</main>
                <Footer dimensions={dimensions} />
                <Analytics />
            </div>
        </MediaLibraryContextProvider>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};
