import React, { createContext, useContext, useRef } from 'react';

export const LayoutContext = createContext({
    isPolicy: false,
    headerRef: null,
    sitesNavRef: null,
    heroCarouselRef: null,
    site: `unknown`,
});

export const LayoutContextProvider = ({ value, children }) => (
    <LayoutContext.Provider
        value={{
            ...value,
            headerRef: useRef(),
            sitesNavRef: useRef(),
            heroCarouselRef: useRef(),
        }}
    >
        {children}
    </LayoutContext.Provider>
);

export const useLayoutContext = () => useContext(LayoutContext);
