import React, { useEffect, useMemo, useState } from 'react';
import { MainMenuSubMenu } from './MainMenuSubMenu';
import { MenuItem } from '../../../models/MenuItem';
import { graphql, useStaticQuery } from 'gatsby';
import { sortMenuItems } from '../../../shared/sortMenuItems';
import { useLayoutContext } from '../../../providers/LayoutProvider';
import { cleanLink } from '../../../shared/cleanLink';
import './MainMenu.scss';

interface Props {
    setSidebarOpen?: boolean | React.Dispatch<React.SetStateAction<boolean>>;
    footer?: boolean;
}

/**
 *
 * This is a little complicated as it actually shows up in three
 * places in the website.
 *
 * The first place it can show up in is the header bar.
 * 'sidebar' and 'footer' are both false. This shows some buttons
 * for each category, and hovering/focusing them will show the SubMenu.
 *
 * The second place it can show up is the sidebar. This is an accordion
 * menu, with collapsing SubMenus. 'sidebar' is true, 'footer' is false.
 *
 * The third place it shows up is the footer. Above a certain breakpoint,
 * it merely shows lists of links per each category. 'sidebar' is false,
 * 'footer' is true. However, below a certain width breakpoint, it reverts
 * to the accordion style, exactly like the sidebar. 'sidebar' is true,
 * 'footer' is true.
 *
 * If 'footer' is true, it pulls in an additional submenu that was created
 * just for the footer section.
 */
export const MainMenu = ({ footer, setSidebarOpen }: Props) => {
    const sitesMenuData = useStaticQuery(graphql`
        query {
            allMenuItems(
                filter: {
                    menu_name: {
                        in: ["main", "public-policy", "footer-spectrum-site"]
                    }
                }
            ) {
                nodes {
                    title
                    menu_name
                    id
                    url
                    weight
                    childrenMenuItems {
                        id
                        title
                        weight
                        menu_name
                        url
                    }
                }
            }
        }
    `);

    const { isPolicy } = useLayoutContext();

    const itemsSrc = sitesMenuData.allMenuItems.nodes.sort(sortMenuItems);
    const [menuItems, setMenuItems] = useState([]);
    const [subMenuState, setSubMenuState] = useState({});
    const [lastFocused, setLastFocused] = useState(``);
    const [wrapperClassNamesStr, setWrapperClassNamesStr] = useState(``);
    const wrapperClassNames = [`main__nav`];

    const toggleAriaExpanded = () => {
        menuItems.forEach((item) => {
            if (subMenuState[item.title]) {
                item.menuExpanded = true;
            } else {
                item.menuExpanded = false;
            }
        });
        setMenuItems([...menuItems]);
    };

    const closeSubMenus = () => {
        const subMenuStateCopy = subMenuState;
        for (const menuItem in subMenuStateCopy) {
            subMenuStateCopy[menuItem] = ``;
        }
        setSubMenuState({ ...subMenuStateCopy });
        toggleAriaExpanded();
    };

    const focusFirstNavButton = () => {
        if (typeof window !== `undefined`) {
            const nav = document.getElementById(`main-nav`);
            const firstNavButton = nav?.getElementsByTagName(`button`)?.[0];
            firstNavButton?.focus();
        }
    };

    const handleSubMenuKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === `Escape`) {
            if (typeof window !== `undefined` && lastFocused) {
                const el = document.getElementById(lastFocused);
                el ? el?.focus() : focusFirstNavButton();
            }
        }
    };

    const handleSubMenuClose = () => {
        closeSubMenus();
    };

    const handleMenuClick = (title: string) => {
        setSubMenuState((prev) => {
            return {
                ...prev,
                [title]: prev[title] === `show` ? `` : `show`,
            };
        });
        toggleAriaExpanded();
    };

    const handleMenuFocus = (title: string) => {
        setLastFocused(title);
        closeSubMenus();
        toggleAriaExpanded();
    };

    const handleMenuKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === `Escape` || (e.key === `Tab` && e.shiftKey)) {
            closeSubMenus();
        }
        toggleAriaExpanded();
    };

    useEffect(() => {
        const nodes = [...itemsSrc];
        if (footer) {
            nodes.push({
                id: `footer-sites-cat`,
                url: ``,
                title: `Spectrum Sites`,
                enabled: true,
                weight: `100`,
                menu_name: ``,
                childrenMenuItems: nodes.filter(
                    (item) => item.menu_name === `footer-spectrum-site`
                ),
            });
        }
        setMenuItems(
            // shallow clone each menu item
            // this keeps the sidebar accordion state
            // from being shared with the footer accordion state
            nodes.map((node) => {
                return { ...node };
            })
        );
    }, [itemsSrc]);

    useEffect(() => {
        if (setSidebarOpen) wrapperClassNames.push(`sidebar`);
        if (footer) wrapperClassNames.push(`footer`);
        if (!setSidebarOpen && footer) {
            wrapperClassNames.push(`show-all`, `kite-container`);
        }
        setWrapperClassNamesStr(wrapperClassNames.join(` `));
    }, [setSidebarOpen, footer, setWrapperClassNamesStr]);

    const parentItems = useMemo(
        () =>
            menuItems
                .filter((item) => item.childrenMenuItems.length)
                .sort(sortMenuItems),
        [menuItems]
    );

    useEffect(() => {
        const subMenuInitState = {};
        parentItems.forEach((item) => {
            subMenuInitState[item.title] = subMenuState[item.title] ?? ``;
        });
        setSubMenuState(subMenuInitState);
    }, [parentItems]);

    if (isPolicy) {
        const policy = parentItems.find((item) =>
            item.title.toLowerCase().includes(`policy`)
        );
        if (policy) {
            if (!parentItems.find((item) => item.menuExpanded)) {
                policy.menuExpanded = true;
            }
            policy.childrenMenuItems = itemsSrc
                .filter((item) => item.menu_name === `public-policy`)
                .map((node): MenuItem => {
                    return {
                        url: node.url,
                        title: node.title,
                        id: node.id,
                        weight: node.weight,
                        enabled: node.enabled,
                        menu_name: `policy`,
                        childrenMenuItems: [],
                    };
                });
        }
    }

    return (
        <nav className={wrapperClassNamesStr} id="main-nav">
            {parentItems.map((item) => {
                const titleId = `menu-${footer ? `footer` : ``}-${cleanLink(
                    item.title
                )}`;
                let buttonLabel = `Navigation menu for ${item.title} category`;
                if (setSidebarOpen) {
                    const actionName = item.menuExpanded
                        ? `Collapse`
                        : `Expand`;
                    buttonLabel = `${actionName} navigation menu for ${item.title} category`;
                }

                if (!footer || setSidebarOpen) {
                    return (
                        <section
                            className={
                                setSidebarOpen && item.menuExpanded
                                    ? `expanded`
                                    : `` + subMenuState[item.title]
                            }
                            key={item.id}
                        >
                            <div className="category">
                                <button
                                    aria-label={buttonLabel}
                                    id={item.title}
                                    className="category"
                                    onClick={() => {
                                        if (!setSidebarOpen) {
                                            handleMenuClick(item.title);
                                        }
                                        for (const pItem of parentItems.filter(
                                            (pItem) => pItem !== item
                                        )) {
                                            pItem.menuExpanded = false;
                                        }
                                        item.menuExpanded = !item.menuExpanded;
                                        setMenuItems([...menuItems]);
                                    }}
                                    onFocus={() => {
                                        if (!setSidebarOpen) {
                                            handleMenuFocus(item.title);
                                        } else {
                                            return true;
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (!setSidebarOpen) {
                                            handleMenuKeyDown(e);
                                        }
                                    }}
                                    aria-expanded={item.menuExpanded}
                                    aria-controls={titleId}
                                >
                                    {item.title}
                                </button>
                            </div>
                            <MainMenuSubMenu
                                items={item.childrenMenuItems}
                                title={item.title}
                                titleId={titleId}
                                footer={footer}
                                ariaHidden={!item.menuExpanded}
                                setSidebarOpen={
                                    setSidebarOpen &&
                                    typeof setSidebarOpen !== `boolean`
                                        ? setSidebarOpen
                                        : undefined
                                }
                                handleClose={() => handleSubMenuClose()}
                                handleKeyDown={handleSubMenuKeyDown}
                            />
                        </section>
                    );
                } else {
                    return (
                        <section className="category" key={`fs-${item.id}`}>
                            <p className="typestack-body-2" id={titleId}>
                                {item.title}
                            </p>
                            <MainMenuSubMenu
                                items={item.childrenMenuItems}
                                title={item.title}
                                titleId={titleId}
                                footer={footer}
                            />
                        </section>
                    );
                }
            })}
        </nav>
    );
};
