import React, { createContext, useContext } from 'react';
import { MediaLibraryCardProps } from '../components/MediaLibraryCard/MediaLibraryCard';

export const MediaLibraryContext = createContext({
    mediaLibraryTabs: [] as MediaLibraryTab[],
    setMediaLibraryTabs: (tabs: MediaLibraryTab[]) => {
        //
    },
});

export const MediaLibraryContextProvider = ({ value, children }) => (
    <MediaLibraryContext.Provider
        value={{
            ...value,
        }}
    >
        {children}
    </MediaLibraryContext.Provider>
);

export const useMediaLibraryContext = () => useContext(MediaLibraryContext);

export interface MediaLibraryTab {
    id?: string;
    name: string;
    count?: number;
    cards: MediaLibraryCardProps[];
    zip?: string;
    downloadSize?: string;
    description?: string;
    image?: any;
    imageAlt?: string;
    route?: string;
    subCategories?: MediaLibraryTab[];
}
